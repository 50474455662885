<template>
  <div v-if="paymentCode" class="rounded origin-payment">
    <img :src="paymentCode" width="40" height="24" loading="lazy" />
  </div>
</template>
<script>
import safeGet from 'just-safe-get'
import orderSetup from '../constants/order'
export default {
  props: {
    scope: {
      type: Object,
      default: null
    },
    origin: {
      type: String,
      default: null
    }
  },
  setup() {
    const orderConst = orderSetup.setup()
    const brands = []
    const brandsIcons = {}
    orderConst.paymentMethodBrands
      .concat(orderConst.paymentMethodCodes)
      .forEach((brand) => {
        if (brand.icon) brandsIcons[brand.value] = brand.icon
        brands.push(brand.value)
      })
    return {
      brands,
      brandsIcons
    }
  },
  computed: {
    paymentCode() {
      let icon = null
      const code =
        this.origin || safeGet(this.scope?.row, this.scope?.column?.property)
      try {
        // Check that the file exists to avoid a 500 error
        icon = require(`@/assets/icons/payment-icons/${this.getIconName(
          code
        )}.svg`)
      } catch (e) {
        // Defaulting to other
        return require(`@/assets/icons/payment-icons/other.svg`)
      }
      return icon
    }
  },
  methods: {
    getIconName(code) {
      return (this.brandsIcons[code] || code).toLowerCase()
    }
  }
}
</script>
<style scoped>
.origin-payment {
  margin-top: 2px;
}
</style>
