<template>
  <el-row :gutter="20" class="mb-4 single-row-info-data">
    <el-col
      :md="6"
      class="name-placeholder"
      :class="{ 'pt-2': firstSlotStyle, 'md:pl-8': nestedSlot }"
      >{{ tag }}</el-col
    >
    <el-col
      :md="18"
      class="highlight overflow-auto"
      :class="{ plain: !info && !hasSlotInfo, 'pt-slot': firstSlotStyle }"
    >
      <div v-if="!hasSlotInfo">{{ propertyValue(info) }}</div>
      <slot v-else />
    </el-col>
  </el-row>
</template>
<script>
export default {
  props: {
    tag: {
      type: String,
      default: null
    },
    info: {
      type: [String, Number],
      default: null
    },
    hasSlotInfo: {
      type: Boolean,
      default: false
    },
    firstSlotStyle: {
      type: Boolean,
      default: false
    },
    nestedSlot: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    notDefined() {
      return this.$t('common.orders.not_defined')
    }
  },
  methods: {
    propertyValue(prop) {
      return prop !== null && prop !== '' ? prop : this.notDefined
    }
  }
}
</script>
<style scoped>
.single-row-info-data > * {
  font-family: Unzer Visuelt;
  font-size: var(--control-font-size);
  line-height: var(--el-line-height);
  color: var(--label-text-color);
  letter-spacing: var(--letter-spacing-table);
}

.highlight {
  color: var(--text-color-input);
}

.plain {
  color: var(--primary-gray);
}

.pt-slot {
  padding-top: var(--button-border-width);
}
</style>
