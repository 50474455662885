<template>
  <div>
    <span class="title">
      {{ title }}
    </span>
    <status-text v-if="status" :status="status" />
    <span v-if="noData" class="no-data float-right text-th-primary-gray mt-1">{{
      $t('pages.order.no_data')
    }}</span>
  </div>
</template>
<script>
import statusText from './status-text'
export default {
  components: {
    statusText
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    status: {
      type: String,
      default: null
    },
    noData: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style scoped>
.title {
  color: var(--text-default-color);
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

.no-data {
  font-family: Unzer Visuelt;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.05em;
  position: absolute;
  right: 50%;
}
</style>
