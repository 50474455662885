<template>
  <th-wrapper
    body-class="p-8 pb-3"
    :collapsable="!customerIsEmpty"
    :full-width-title="customerIsEmpty"
  >
    <template #title>
      <title-text
        :title="$t('pages.order.customer.subtitle')"
        :no-data="customerIsEmpty"
      />
    </template>
    <div v-if="!customerIsEmpty">
      <el-row :gutter="20" class="mb-8">
        <el-col v-if="!hideCustomerInfo" :md="8">
          <div class="flex flex-col">
            <span
              v-if="customer.firstName || customer.lastName"
              class="u-body-details"
            >
              {{ customer.firstName + ' ' + customer.lastName }}
            </span>
            <span class="u-body-details">
              {{ customer.email }}
            </span>
            <span v-if="customer.birthDate" class="u-body-details">
              {{ customer.birthDate }} ({{ calculateAge(customer.birthDate) }})
            </span>
            <span class="u-body-details">
              {{ customer.mobile }}
            </span>
            <span class="u-body-details">
              {{ customer.phone }}
            </span>
          </div>
        </el-col>
        <el-col :md="8">
          <div class="flex flex-col">
            <span class="u-table-section-subtitle">
              {{ $t('common.forms.labels.addresses.types.billing') }}
              {{ $t('common.forms.labels.addresses.types.local') }}
            </span>
            <span v-if="customer.address?.street" class="u-body-details">
              {{ customer.address.street }}
            </span>
            <span v-if="customer.address?.street" class="u-body-details">
              {{ customer.address.street2 }}
            </span>
            <span
              v-if="
                customer.address?.zip ||
                customer.address?.city ||
                customer.address?.state
              "
              class="u-body-details"
            >
              {{ customer.address.zip }}
              {{ customer.address.city }}
              {{ customer.address.state }}
            </span>
            <span v-if="customer.address?.country" class="u-body-details">
              {{ customer.address.country }}
            </span>
          </div>
        </el-col>
        <el-col v-if="!shippingAddressIsEmpty" :md="8">
          <div
            v-if="sameAddress(customer.address, customer.shippingAddress)"
            class="flex flex-col"
          >
            <span class="u-table-section-subtitle">
              {{ $t('pages.order.customer.shipping_address.subtitle') }}
              {{ $t('common.forms.labels.addresses.types.local') }}
            </span>
            <span class="u-body-details">
              <i>{{
                $t('pages.order.customer.shipping_address.same_as_billing')
              }}</i>
            </span>
          </div>
          <div v-else class="flex flex-col">
            <span class="u-table-section-subtitle">
              {{ $t('pages.order.customer.shipping_address.subtitle') }}
              {{ $t('common.forms.labels.addresses.types.local') }}
            </span>
            <span class="u-body-details">
              {{ customer.shippingAddress.street }}
            </span>
            <span class="u-body-details">
              {{ customer.shippingAddress.street2 }}
            </span>
            <span class="u-body-details">
              {{ customer.shippingAddress.zip }}
              {{ customer.shippingAddress.city }}
              {{ customer.shippingAddress.state }}
            </span>
            <span class="u-body-details">
              {{ customer.shippingAddress.country }}
            </span>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20" class="py-4">
        <el-col :md="5">
          <div class="flex flex-col">
            <span class="u-table-section-subtitle">
              {{ $t('pages.orders.all.filters.customer_id.label') }}
            </span>
            <span
              class="u-body-details"
              :class="{ 'text-th-primary-gray': !customer.customerId }"
            >
              {{ customer.customerId ? customer.customerId : notDefined }}
            </span>
          </div>
        </el-col>
        <el-col :md="4">
          <div class="flex flex-col">
            <span class="u-table-section-subtitle">
              {{ $t('pages.order.customer.language.label') }}
            </span>
            <span
              class="u-body-details"
              :class="{ 'text-th-primary-gray': !customer.language }"
            >
              {{ customer.language ? customer.language : notDefined }}
            </span>
          </div>
        </el-col>
        <el-col :md="3">
          <div class="flex flex-col">
            <span class="u-table-section-subtitle">
              {{ $t('common.titles.title') }}
            </span>
            <span
              class="u-body-details"
              :class="{ 'text-th-primary-gray': !customer.salutation }"
            >
              {{ customer.salutation ? customer.salutation : notDefined }}
            </span>
          </div>
        </el-col>
        <el-col :md="12">
          <div class="flex flex-col">
            <span class="u-table-section-subtitle">
              {{ $t('pages.order.customer.company.label') }}
            </span>
            <span
              class="u-body-details"
              :class="{ 'text-th-primary-gray': !customer.company }"
            >
              {{ customer.company ? customer.company : notDefined }}
            </span>
          </div>
        </el-col>
      </el-row>
    </div>
  </th-wrapper>
</template>
<script>
import titleText from './title-text'
export default {
  components: {
    titleText
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      customerInfoFields: [
        'firstName',
        'lastName',
        'birthDate',
        'mobile',
        'phone'
      ]
    }
  },
  computed: {
    customer() {
      return this.modelValue?.customer || {}
    },
    customerInfo() {
      const customerInfo = {}
      this.customerInfoFields.forEach((fieldName) => {
        if (this.customer[fieldName])
          customerInfo[fieldName] = this.customer[fieldName]
      })
      return customerInfo
    },
    customerIsEmpty() {
      return Object.keys(this.customer).length === 0
    },
    hideCustomerInfo() {
      return Object.keys(this.customerInfo).length === 0
    },
    notDefined() {
      return this.$t('common.orders.not_defined')
    },
    shippingAddressIsEmpty() {
      return Object.keys(this.customer?.shippingAddress || {}).length === 0
    }
  },
  methods: {
    sameAddress(billing, shipping) {
      if (
        billing?.street === shipping?.street &&
        billing?.zip === shipping?.zip &&
        billing?.country === shipping?.country
      ) {
        return true
      }
      return false
    },
    calculateAge(birthdate) {
      if (birthdate) {
        const [day, month, year] = birthdate.split('.').map(Number)
        const currentDate = new Date()
        const birthdateObject = new Date(year, month - 1, day)

        let age = currentDate.getFullYear() - birthdateObject.getFullYear()
        if (
          currentDate.getMonth() < birthdateObject.getMonth() ||
          (currentDate.getMonth() === birthdateObject.getMonth() &&
            currentDate.getDate() < birthdateObject.getDate())
        ) {
          age--
        }
        return age
      }
      return
    }
  }
}
</script>
